import React, { useState } from 'react'
import { motion, AnimatePresence } from 'framer-motion';

import { authModalVars } from '../../lib/animation';

import Login from './login';
import Register from './register';

const AuthForm = () => {
  const [isRegister, setIsRegister] = useState(false)
 
  return (
    <div className="block w-full">
      <ul className="flex text-md mb-6">
        <li><button onClick={()=>{setIsRegister(false)}} className={`hover:underline mr-4 transition duration-200 ${isRegister ? 'opacity-50' : ''}`}>Log in</button></li>
        <li><button onClick={()=>{setIsRegister(true)}} className={`hover:underline transition duration-200 ${isRegister ? '' : 'opacity-50'}`}>Create account</button></li>
        </ul>
        <AnimatePresence exitBeforeEnter>
          <motion.div
            variants={authModalVars}
            key={isRegister}
            initial="closed"
            animate="open"
            exit="closed"
          >
        {isRegister ? (
          <Register />
        ) : (
          <Login />
        )}     
        </motion.div>
        </AnimatePresence>   
    </div>
  )
}

export default AuthForm