import React, { useState, useEffect } from 'react'
import { Link } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image"
import ReactPlayer from 'react-player'
import { motion, AnimatePresence } from 'framer-motion';

import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
// import "swiper/css/free-mode";
import { Mousewheel, Navigation } from 'swiper';

// Animation
import { productGalleriesVars } from '../../lib/animation';


const GallerySet = ({ gallery, galleryIndex }) => {
    return (
        <>        
        <Swiper      
            id={gallery.title ? gallery.title.toLowerCase().replace(/ /g,'-').replace(/[^\w-]+/g,'') : `gallery-${galleryIndex}`}      
            className={`swiper-gallery lg:snap-start group ${galleryIndex > 0 ? 'hidden lg:flex' : ''}`}
            modules={[Mousewheel, Navigation]}                                           
            speed={600}
            loop={true}          
            mousewheel={{
                forceToAxis: true
            }}
            spaceBetween={0}      
            slidesPerView={1}      
            navigation={{
                nextEl: '.gallery-next',
                prevEl: '.gallery-prev',
            }}                                                            
          >
            <button className="gallery-prev absolute left-0 top-0 bottom-0 w-1/2 z-10 flex items-center justify-start pl-gutter mix-blend-difference xl:opacity-0 xl:group-hover:opacity-100 transition duration-300">                
                <svg className="w-5 h-5 stroke-current transition duration-300 xl:hover:scale-125" xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" viewBox="0 0 16 16">
                    <path fillRule="evenodd" d="M11.354 1.646a.5.5 0 0 1 0 .708L5.707 8l5.647 5.646a.5.5 0 0 1-.708.708l-6-6a.5.5 0 0 1 0-.708l6-6a.5.5 0 0 1 .708 0z"/>
                </svg>
            </button>
            <button className="gallery-next absolute right-0 top-0 bottom-0 w-1/2 z-10 flex items-center justify-end pr-gutter mix-blend-difference xl:opacity-0 xl:group-hover:opacity-100 transition duration-300">                
                <svg className="w-5 h-5 stroke-current transition duration-300 xl:hover:scale-125" xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" viewBox="0 0 16 16">
                    <path fillRule="evenodd" d="M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z"/>
                </svg>            
            </button>
            {gallery && gallery.items && gallery.items.map((item, index) => {
                return (
                    <SwiperSlide 
                        className="w-full"
                        key={`gallery-item-${index}`}
                    >
                        {galleryIndex === 0 && <div className="hidden lg:block w-full h-header"></div>}
                        {item._type === 'image' ? (
                            <>  
                            {item.asset && item.asset.gatsbyImageData && <GatsbyImage image={item.asset.gatsbyImageData} alt={''}  className={`w-full aspect-square lg:aspect-auto object-cover ${galleryIndex > 0 ? 'lg:h-screen' : 'lg:h-screenHeader'}`} />}                                                    
                            </>
                        ) : (
                            <div className={`aspect-square lg:aspect-auto relative ${galleryIndex > 0 ? 'lg:h-screen' : 'lg:h-screenHeader'}`}>
                            <ReactPlayer       
                                type="video/mp4"       
                                url={item.asset.url}
                                playing={true}
                                muted={true}
                                loop={true}
                                playsinline={true}       
                                width='100%'
                                height='100%'
                                className="w-full h-full absolute inset-0 bg-vid" 
                            />                                  
                            </div>
                        )}
                    </SwiperSlide>
                )
            })}
        </Swiper> 
        </>
    )
}


const ProductGalleries = ({ galleries, imageHero, className }) => {

    const [currentGallery, setCurrentGallery] = useState(0)        

    function handleClick(index) { 
        setCurrentGallery(index)    
    }

   
  return (
    <div className={`${className}`}>                
        
        <ul className="fixed hidden lg:flex flex-col items-start top-header pt-gutter left-4 z-20 mix-blend-difference">
            {galleries.map((gallery, index) => {                
                if (gallery.title !== null) {
                    return (
                        <>
                        {gallery.title && <Link className={`mb-2 xl:hover:underline ${index === currentGallery ? '' : ''}`} to={`#${gallery.title.toLowerCase().replace(/ /g,'-').replace(/[^\w-]+/g,'')}`}>{gallery.title}</Link>}
                        </>                                        
                    )
                }                
            })}
        </ul>
        
        {galleries && galleries[0] ?             
        <div className="lg:snap-y lg:snap-mandatory lg:overflow-y-scroll scroll-smooth">
            <div className="hidden lg:block">
                {galleries.map((gallery, index) => {                
                    return (                                        
                        <GallerySet gallery={gallery} galleryIndex={index} />                    
                    )                
                })}   
            </div>
            <Swiper                  
            className={`lg:hidden swiper-gallery group`}
            modules={[Mousewheel, Navigation]}                                           
            speed={600}
            loop={true}          
            mousewheel={{
                forceToAxis: true
            }}
            spaceBetween={0}      
            slidesPerView={1}      
            navigation={{
                nextEl: '.mob-gallery-next',
                prevEl: '.mob-gallery-prev',
            }}                                                            
          >
              <button className="mob-gallery-prev absolute left-0 top-0 bottom-0 w-1/2 z-10 flex items-center justify-start pl-gutter mix-blend-difference xl:opacity-0 xl:group-hover:opacity-100 transition duration-300">                
                <svg className="w-5 h-5 stroke-current transition duration-300 xl:hover:scale-125" xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" viewBox="0 0 16 16">
                    <path fillRule="evenodd" d="M11.354 1.646a.5.5 0 0 1 0 .708L5.707 8l5.647 5.646a.5.5 0 0 1-.708.708l-6-6a.5.5 0 0 1 0-.708l6-6a.5.5 0 0 1 .708 0z"/>
                </svg>
            </button>
            <button className="mob-gallery-next absolute right-0 top-0 bottom-0 w-1/2 z-10 flex items-center justify-end pr-gutter mix-blend-difference xl:opacity-0 xl:group-hover:opacity-100 transition duration-300">                
                <svg className="w-5 h-5 stroke-current transition duration-300 xl:hover:scale-125" xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" viewBox="0 0 16 16">
                    <path fillRule="evenodd" d="M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z"/>
                </svg>            
            </button>
            {galleries.map((gallery) => {                
                return (
                    <>
                    {gallery.items && gallery.items.map((item, index) => {
                    return (
                        <SwiperSlide 
                        className="w-full"
                        key={`gallery-item-${index}`}
                    >                            
                        {item._type === 'image' ? (
                            <>  
                            {item.asset && item.asset.gatsbyImageData && <GatsbyImage image={item.asset.gatsbyImageData} alt={''}  className={`w-full aspect-square object-cover`} />}                                                    
                            </>
                        ) : (
                            <div className={`aspect-square relative`}>
                            <ReactPlayer       
                                type="video/mp4"       
                                url={item.asset.url}
                                playing={true}
                                muted={true}
                                loop={true}
                                playsinline={true}       
                                width='100%'
                                height='100%'
                                className="w-full h-full absolute inset-0 bg-vid" 
                            />                                  
                            </div>
                        )}
                    </SwiperSlide>
                    )
                })}
                    </>
                )
            })}  
            </Swiper>            
        </div>
          : (
            <>            
            <div className="hidden lg:block w-full h-header"></div>            
              {imageHero && imageHero.asset && imageHero.asset.gatsbyImageData && (
                <GatsbyImage 
                  className="w-full h-auto lg:h-screenHeader object-cover" 
                  image={imageHero.asset.gatsbyImageData} 
                  alt={imageHero.asset.alt} 
                />                
              )}
            </>
          )
          }
    </div>
  )
}

export default ProductGalleries