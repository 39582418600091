import React from 'react'
import { navigate } from "@reach/router"
import { Link } from "gatsby"

import { useSiteState } from '../../lib/siteContext';
import { useEffect } from 'react';

const CustomiseCta = ({ title, slug, basePrice, fromPrice, showPrice }) => {
  const { user, cartProject, setShowAuthModal } = useSiteState();


  useEffect(()=>{
    console.log('basePrice, fromPrice, showPrice', basePrice, fromPrice, showPrice)
  },[])

  function handleClick() {
    if (user !== null && cartProject !== null) {
      navigate(`/builder/${slug.current}`)      
    } 
    if (user !== null && cartProject === null) {
      setShowAuthModal(true)
    }   
    if (user === null && cartProject === null) {
      setShowAuthModal(true)
    }   
  }
  return (
    <div className="customise_cta grid-std text-sm fixed bottom-0 left-0 right-0 z-30 text-white bg-doc-bg/30 bg-opacity-30 backdrop-blur-xl px-gutter lg:px-0 py-2 md:py-4 flex justify-between items-center">
      <div className="col-span-4 lg:col-start-2 lg:col-end-14">
        <Link to={`/packaging/${slug.current}`} className="outline-doc-text text-md lg:text-lg">{title && title}</Link>
      </div>        
      <div className="hidden lg:flex lg:flex-col col-span-4">
        {basePrice && !!showPrice && (
        <>
        <span className="text-pp-grey70">NZD</span>
        
        <span className="lg:text-md">${basePrice}{` `}per item</span>
        </>
        )}  
      </div>                
      <div className="hidden lg:flex lg:flex-col col-span-4">
        {fromPrice && !!showPrice && (
          <>
          <span className="text-pp-grey70">From</span>
          <span className="lg:text-md">NZD ${fromPrice} per unit</span>
          </>
        )}             
      </div>                 
      <div className="col-span-4 flex justify-end">
        <button type="button" className="appearance-none form-trigger" onClick={()=>{handleClick()}}>Customise</button>
      </div>        
    </div>
  )
}

export default CustomiseCta