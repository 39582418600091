import React, { useEffect } from 'react';
import { graphql } from 'gatsby';

// Hooks & Querys & Context
import { useSiteState } from '../lib/siteContext';

// Components
import ProductOrderInfo from '../components/products/productOrderInfo'
import ProductFeaturesCerts from '../components/products/productFeaturesCerts'
import ProductBuilderModal from "../components/products/productBuilderModal"
import ProductBuilderCta from "../components/products/productBuilderCta"
import ProductMaterials from "../components/products/productMaterials"
import ProductHeader from "../components/products/productHeader"
import ProductGalleries from '../components/products/productGalleries';
import Seo from '../components/global/seo'
import Layout from '../components/layout'



const ProductTemplate = ({ data: { sanityProduct } }) => {
  
  const { 
    title,
    description,
    customisableAspects,
    seo,
    quantity,
    leadTime, 
    imageHero,
    materials,
    wovens,
    textures,
    foils,    
    enableBuilder,
    slug,        
    sustainabilityOptions,
    certificationOptions,
    galleries,
    features,
    finishes,
    materialsText,
    featuresText,    
    basePrice,
    fromPrice,
    showPrice
   } = sanityProduct || {}  

  const {     
    setPageFeatureColor, 
    setPageBackground, 
    setShowingBriefUs, 
    setFooterHidden, 
    setFooterForms,
    setHeaderDark,
    setMyPorter    
  } = useSiteState();

  useEffect(() => {    
    document.documentElement.setAttribute('data-theme', `theme-black`);
    document.documentElement.style.setProperty(`--doc-bg`, '#141414');          
    setMyPorter(false)
    setHeaderDark(false)
    setFooterForms(false)
    setPageFeatureColor(null)
    setShowingBriefUs(true)
    setFooterHidden(true)
  }, [
    setPageBackground, 
    setFooterHidden, 
    setMyPorter, 
    setHeaderDark, 
    setPageFeatureColor, 
    setFooterForms, 
    setShowingBriefUs
  ]);

  return (
    <Layout>     
        
        {seo && <Seo {...seo} />}                   
        
        <section className="grid-std gap-y-8 lg:gap-y-0 mb-20 lg:mb-0 lg:items-start">                    
          
          <ProductGalleries 
            galleries={galleries} 
            imageHero={imageHero} 
            className="row-start-1 lg:row-start-2 col-start-1 col-end-9 lg:col-start-1 lg:col-end-14 relative" 
          />
          
          <div className="row-start-2 col-start-2 col-end-8 lg:col-start-14 lg:pl-gutter lg:sticky lg:top-0 lg:h-screen lg:overflow-y-scroll hide-scrollbar lg:col-end-26 xl:col-end-25 lg:flex lg:flex-col xl:justify-start lg:pt-32 lg:pb-24">                          
            
            <ProductHeader 
              {...{ 
                
                imageHero, 
                title, 
                description,                 
                quantity, 
                featuresText, 
                features, 
                certificationOptions 
              }}
            />

            <div className="grid grid-flow-row grid-cols-2 items-start">

              <ProductOrderInfo 
                {...{ 
                  leadTime, 
                  quantity,
                  customisableAspects,
                  quantity, 
                  featuresText, 
                  features, 
                  certificationOptions 
                }} 
              /> 

              <ProductFeaturesCerts 
                {...{ 
                  featuresText, 
                  features, 
                  certificationOptions,
                  sustainabilityOptions
                }} 
              /> 

            </div>
            
                                   
            
            {/* <ProductMaterials 
              {...{ 
                materials, 
                wovens, 
                materialsText, 
                sustainabilityOptions 
              }} 
            />                      */}

         </div>         

       </section>                                     

        {enableBuilder && (
          <ProductBuilderCta 
            {...{ 
              title, 
              slug, 
              basePrice, 
              fromPrice, 
              showPrice 
            }} 
          />
        )}

        <ProductBuilderModal slug={slug} />        

    </Layout>
  );
};

export default ProductTemplate;

export const pageQuery = graphql`
  query productQuery($slug: String!) {
    sanityProduct(slug: { current: { eq: $slug } }) {
        title
        slug {
          current
        }
        description: _rawDescription(resolveReferences: {maxDepth: 10})
        customisableAspects: _rawCustomisableAspects(resolveReferences: {maxDepth: 10})
        closuresText: _rawClosuresText(resolveReferences: {maxDepth: 10})
        featuresText: _rawFeaturesText(resolveReferences: {maxDepth: 10})
        materialsText: _rawMaterialsText(resolveReferences: {maxDepth: 10})
        quantity
        leadTime
        orientation
        enableBuilder
        basePrice
        fromPrice
        showPrice
        imageHero {
            asset {
                gatsbyImageData
            }
        }
        imageClosures {
            asset {
                gatsbyImageData
            }
        }
        materialsImage {
            asset {
                gatsbyImageData
            }
        }
        seo {
            ...SEO
        }
        sizes {
          size
          sizeInfo
        }
        orientations
        quantities
        printingOptions
        colourOptions {
          title
          subtitle
        }
        sustainabilityOptions {
          title
          icon
        }
        certificationOptions {
          title
          icon
        }
        galleries {
          title
          items {
            ... on SanityFile {
              _key
              _type
              asset {
                url
              }
            }
            ... on SanityImage {
              _type
              asset {
                  gatsbyImageData
              }
            }
          }
        }
        closureOptions {
          name
          illustration {
            asset {
                gatsbyImageData
            }
          }
        }
        materials {
          title
          description: _rawDescription(resolveReferences: {maxDepth: 10})
          imagePrimary {
            asset {
                gatsbyImageData
            }
          }
        }
        wovens {
          title
          description: _rawDescription(resolveReferences: {maxDepth: 10})
          imagePrimary {
            asset {
                gatsbyImageData
            }
          }
        }
        textures {
          id
            title
            description: _rawDescription(resolveReferences: {maxDepth: 10})
            imagePrimary {
              asset {
                  gatsbyImageData
              }
            }
        }
        foils {
          id
            title
            imagePrimary {
              asset {
                  gatsbyImageData
              }
            }
        }
        finishes {
          title
        }
        features {
          title
        }
    }
  }
`;
 