import React from 'react'

const CertificationTag = ({ title, icon }) => {
  return (
    <div className="h-6 px-3 bg-pp-grey70 rounded-full mr-2 flex items-center">
        {icon && <div className="w-3 h-auto mr-2" dangerouslySetInnerHTML={{ __html: icon }} />}
        <span>{title && title}</span>
    </div>
  )
}

export default CertificationTag