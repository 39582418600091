import React from 'react'

import { PortableTextBlock } from "../ui/portableTextBlock"
import FeatureTag from "../ui/featureTag"
import CertificationTag from "../ui/certificationTag"

const ProductOrderInfo = ({ featuresText, features, customisableAspects, leadTime, quantity }) => {
  return (
    <>
    <div className="mb-10 grid grid-flow-row grid-cols-1 gap-y-6">
        {customisableAspects && (
            <div className="col-span-1">
                <span className="opacity-60 block">Fully Customisable</span>
                <PortableTextBlock text={customisableAspects} className="prose" />
            </div>
        )}
          {quantity && (
            <div>
                <span className="opacity-60 block">Minimum Order Quantity</span>
                <span>{quantity}</span>
                </div>
          )}
          {leadTime && (
            <div>
                <span className="opacity-60 block">Production Lead Time</span>
                <span>{leadTime}</span>
                </div>
          )}
        </div>       
    </>
  )
}

export default ProductOrderInfo