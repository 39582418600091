import { useStaticQuery, graphql } from 'gatsby';

export const useBuilder = () => {
  const { sanityBuilderSettings } = useStaticQuery(graphql`
    query {
      sanityBuilderSettings {
        titleSize,
        titleDesc,  
        titleOrientation,
        descOrientation,
        titleClosure,
        descClosure,
        titleMaterial,
        descMaterial,
        titlePrintingStage,
        titlePrinting,
        descPrinting,
        titleColour,
        descColour,
        titleAdditions,
        titleEmbossing,
        descEmbossing,
        titleFoils,
        descFoils,
        infoFoils: _rawInfoFoils(resolveReferences: {maxDepth: 10}),
        titleTextures,
        descTextures,
        infoTextures: _rawInfoTextures(resolveReferences: {maxDepth: 10}),
        titleFinish,
        descFinish,
        titleFeatures,
        descFeatures,
        titleQuantity,
        descQuantity,
        titleDielines,
        descDielines,
        titleSummary,
        descSummary,
        popupTitle,
        popupText: _rawPopupText(resolveReferences: {maxDepth: 10}),
        popupBtnMain,
        popupBtnSecondary,
        cartTitle,
        cartEmptyDescription: _rawCartEmptyDescription(resolveReferences: {maxDepth: 10}),
        cartEmptyButton,
        checkoutTitle,
        checkoutText: _rawCheckoutText(resolveReferences: {maxDepth: 10}),
      }
    }
  `);
  return sanityBuilderSettings || {};
};
