import React from 'react'

import { PortableTextBlock } from "../ui/portableTextBlock"
import FeatureTag from "../ui/featureTag"
import CertificationTag from "../ui/certificationTag"
import SustainabilityOption from "../ui/sustainabilityOption"

const FeaturesCertifications = ({ featuresText, features, certificationOptions, sustainabilityOptions }) => {
  return (
    <div className="grid grid-flow-row grid-cols-1 gap-y-6">
    
    {featuresText && <PortableTextBlock text={featuresText} className="prose mb-6" />}     
    
    {features[0] && (
      <div>
      <h3>Features</h3>
      <ul className="project-tag-list mt-2">
        {features.map((feature) => (
          <li className="mr-2"><FeatureTag {...feature} /></li>
        ))}
      </ul>
      </div>
    )}    
    
    {certificationOptions[0] &&  (
      <div>
      <h3>Certification options</h3>
      <ul className="project-tag-list mt-2">
        {certificationOptions.map((option) => (
          <li className="mr-2"><CertificationTag {...option} /></li>
        ))}
      </ul>
      </div>
    )}    

    {sustainabilityOptions[0] && (
      <div>
        <h3>Sustainability Options</h3>
        <ul className="project-tag-list mt-2">
            {sustainabilityOptions.map((option) => (
              <li className="mr-2"><SustainabilityOption {...option} /></li>
            ))}
        </ul>
      </div>
    )}       
    </div>
  )
}

export default FeaturesCertifications