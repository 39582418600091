import React from 'react'

import { PortableTextBlock } from "../ui/portableTextBlock"

const ProductHeader = ({ title, description }) => {
  return (
    <>
      <h1 className="text-xl xl:text-productTitle mb-10">{title && title}</h1>
        {description && <PortableTextBlock className="prose max-w-none mb-10" text={description} />}              
       </>
  )
}

export default ProductHeader