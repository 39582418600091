import React from "react"
import { navigate } from "gatsby"
   
class Register extends React.Component {
  state = {
    FirstName: ``,
    LastName: ``,
    CompanyName: ``,
    Phone: ``,
    EmailAddress: ``,
    Password: ``,    
    formMessage: null,
  }

  handleUpdate = event => {
    this.setState({
      [event.target.name]: event.target.value,
    })
  }

  handleSubmit = async event =>  {
    event.preventDefault()    
    const response = await window
      .fetch(`/api/register`, {
        method: `POST`,
        headers: {
          "content-type": "application/json",
        },
        body: JSON.stringify({
            FirstName: this.state.FirstName,
            LastName: this.state.LastName,
            CompanyName: this.state.CompanyName,
            Phone: this.state.Phone,
            EmailAddress: this.state.EmailAddress,
            Password: this.state.Password
        }),
      })      
      .then((response) => response.json())
      .then((data) => {
        console.log(data)
        if (data === false) {
            this.setState({
                formMessage: "There was an error.",
            })
            return
        }
        if (data === true) {
            // localStorage.setItem('user:account', JSON.stringify(data.contactDetails));            
            // document.cookie = `login=sample@gmail.com`            
            // navigate(`/account`)
            this.setState({
                formMessage: "Signup successful, please log in.",
            })
        }
      });
  }

  render() {    

    return (
      <>        
        <form
         className="grid grid-cols 1 md:grid-cols-2 gap-y-8 gap-gutter lg:gap-6 grid-flow-row items-center styled-form"
          method="post"
          onSubmit={event => {
            this.handleSubmit(event)            
          }}
        >
            <label>            
                <input 
                type="text" 
                name="FirstName" 
                placeholder="First name" 
                onChange={this.handleUpdate} 
                />
            </label>
            <label>            
                <input 
                type="text" 
                name="LastName" 
                placeholder="Last name" 
                onChange={this.handleUpdate} 
                />
            </label>
            <label>            
                <input 
                type="text" 
                name="CompanyName" 
                placeholder="Company name" 
                onChange={this.handleUpdate} 
                />
            </label>
            <label>            
                <input 
                type="text" 
                name="Phone" 
                placeholder="Phone" 
                onChange={this.handleUpdate} 
                />
            </label>
            <label>            
                <input
                type="email"
                name="EmailAddress"
                placeholder="Email address"
                onChange={this.handleUpdate}
                />
            </label>
            <label>            
                <input
                type="password"
                name="Password"
                placeholder="Password"
                onChange={this.handleUpdate}
                />
            </label>
          <div>
            <a className="opacity-50 hover:opacity-100 focus:opacity-100 text-sm" href="/">Forgot password?</a>
          </div>
          <div className="flex justify-end">
            <button type="submit">Log In</button>
          </div>          
          {this.state.formMessage && <div>{this.state.formMessage}</div>}
        </form>
      </>
    )
  }
}

export default Register