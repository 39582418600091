import React from "react"
import { navigate } from "gatsby"
   
class Login extends React.Component {
  state = {
    username: ``,
    password: ``,
    formMessage: null,
  }

  handleUpdate = event => {
    this.setState({
      [event.target.name]: event.target.value,
    })
  }

  handleSubmit = async event =>  {
    event.preventDefault()    
    const response = await window
      .fetch(`/api/login`, {
        method: `POST`,
        headers: {
          "content-type": "application/json",
        },
        body: JSON.stringify({
            username: this.state.username,
            password: this.state.password,
            remember: true
        }),
      })      
      .then((response) => response.json())
      .then((data) => {
        if (data.success === false) {
            this.setState({
                formMessage: data.message,
            })
            return
        }
        if (data.success === true) {
            localStorage.setItem('user:account', JSON.stringify(data.contactDetails));            
            // document.cookie = `login=sample@gmail.com`            
            navigate(`/account`)
            this.setState({
                formMessage: null,
            })
        }
      });
  }

  render() {    

    return (
      <>        
        <form
         className="grid grid-cols 1 md:grid-cols-2 gap-y-8 gap-gutter lg:gap-6 grid-flow-row items-center styled-form"
          method="post"
          onSubmit={event => {
            this.handleSubmit(event)            
          }}
        >
          <label>            
            <input 
              type="text" 
              name="username" 
              placeholder="Email Address" 
              onChange={this.handleUpdate} 
              />
          </label>
          <label>            
            <input
              type="password"
              name="password"
              placeholder="Password"
              onChange={this.handleUpdate}
            />
          </label>
          <div>
            <a className="opacity-50 hover:opacity-100 focus:opacity-100 text-sm" href="/">Forgot password?</a>
          </div>
          <div className="flex justify-end">
            <button type="submit" className="btn-arrow">
              <span>Log In</span>
              <svg width="18" height="11" viewBox="0 0 18 11" className="stroke-current ml-2" xmlns="http://www.w3.org/2000/svg">
                <path d="M1 5.5L17 5.5M17 5.5L13 1.5M17 5.5L13 9.5" strokeWidth="1.3" strokeLinecap="round" strokeLinejoin="round"/>
              </svg>
            </button>
          </div>          
          {this.state.formMessage && <div>{this.state.formMessage}</div>}
        </form>
      </>
    )
  }
}

export default Login