import React from 'react'

const SustainabilityOption = ({ title, icon }) => {
  return (
    <div className="h-6 px-3 bg-pp-light-green rounded-full flex items-center">
    {icon && <div className="w-4 h-auto mr-2" dangerouslySetInnerHTML={{ __html: icon }} />}
    <span>{title && title}</span>
    </div>
  )
}

export default SustainabilityOption