import React, { useEffect, useState } from 'react'
import { motion, AnimatePresence } from 'framer-motion';
import { Link, navigate } from "gatsby"

import { useSiteState } from '../../lib/siteContext';
import { useScrollFreeze } from "../../lib/useScrollFreeze"
import { useBuilder } from '../../GraphQl/useBuilder';

import AuthForm from "../user/authForm"
import { PortableTextBlock } from "../ui/portableTextBlock"

//Animation
const authModalVariants = {
  closed: {
    opacity: 0,
    pointerEvents: 'none',
    transition: {
      duration: 0.3,
    },
  },
  open: {
    opacity: '100%',
    pointerEvents: 'auto',
    transition: {
      duration: 0.3,
    },
  },
};

const ModalInner = ({ slug }) => {
  
  const { user, cartProject } = useSiteState();
  const [nameInput, setNameInput] = useState(cartProject || '');
  const [emailInput, setEmailInput] = useState('');
  // const [valid, setValid] = useState(false);

  const { 
    popupTitle,
    popupText,
    popupBtnMain,
    popupBtnSecondary
   } = useBuilder()

  
   useScrollFreeze()  


  
   function handleChange(projectName) {
    setNameInput(projectName);
    localStorage.setItem('projectName', JSON.stringify(projectName));
  }
  function handleEmailChange(emailAddress){
    setEmailInput(emailAddress)
    localStorage.setItem('projectEmail', JSON.stringify(emailAddress));
  }

  const handleNavigate = (route) => {

    if(/.+@.+\..+/.test(emailInput)){
      fetch(`/api/builder/started/${emailInput}`)
    }
    
    navigate(route)
  }
  
  return (
    <div>    
        {user ? (
          <>
          <p className="text-lg pt-8 mb-6">{popupTitle && popupTitle}</p>
          <div className="prose">{popupText && <PortableTextBlock className="prose md:max-w-xl my-6" text={popupText} />}</div>
          <div className="mt-12 grid grid-cols-1 gap-gutter lg:gap-6 grid-flow-row styled-form">            
          {/* <form></form> */}
            <input type="text" placeholder="Give your project a name" className="text-md input-std" value={nameInput} onChange={(e) => handleChange(e.target.value)}/>
            <input type="text" placeholder="Email Address" className="text-md input-std" value={emailInput} onChange={(e) => handleEmailChange(e.target.value)}/>
            <div className="flex justify-end">                        
            <div onClick={() => handleNavigate(`/builder/${slug.current}`)} className={`btn-arrow ${nameInput !== undefined ? 'opacity-100' : 'pointer-events-none opacity-50'}`}>
              <span>{popupBtnMain ? popupBtnMain : 'Continue to the quote builder'}</span>
              <svg width="18" height="11" viewBox="0 0 18 11" className="stroke-current ml-2" xmlns="http://www.w3.org/2000/svg">
                <path d="M1 5.5L17 5.5M17 5.5L13 1.5M17 5.5L13 9.5" strokeWidth="1.3" strokeLinecap="round" strokeLinejoin="round"/>
              </svg>
              </div>
            </div>
          </div>
          <div className="mt-16 flex justify-end hidden">
              <Link to={`/builder/${slug.current}`} className="btn-continue">
                <span>{popupBtnSecondary ? popupBtnSecondary : 'Continue without naming project'}</span>
                <svg width="15" height="9" viewBox="0 0 15 9" className="stroke-current ml-2" xmlns="http://www.w3.org/2000/svg">
                  <path d="M1 4.5H14M14 4.5L10 1M14 4.5L10 8" strokeLinecap="round" strokeLinejoin="round"/>
                </svg>
              </Link>
          </div>
          </>
        ) : (
          <>
          <p className="text-lg pt-8 mb-6">Sign in</p>
            <p className="max-w-md">In order to save your sample you'll need to be signed in. Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p>
            <div className="mt-12">            
              <AuthForm />          
            </div>
            <div className="mt-8 flex justify-end">
                <Link to={`/builder/${slug.current}`} className="btn-rnd-xs px-4 opacity-50 hover:opacity-100 focus:opacity-100">Continue without signing in</Link>
            </div>
          </>
        )}
    </div>
  )
}

const ProductBuilderModal = ({ slug }) => {
    const { showAuthModal, setShowAuthModal } = useSiteState();  

  return (
    <>    
    <AnimatePresence exitBeforeEnter>
        {showAuthModal && (
          <motion.section 
            initial="closed"
            animate="open"
            exit="closed"
            variants={authModalVariants}            
            className={`authmodal fixed inset-0 z-3000 p-gutter flex items-center justify-center max-h-screen`}
         >
           <div className="bg-black bg-opacity-70 absolute inset-0 z-0" onClick={() => setShowAuthModal(false)}></div>
           <motion.div 
           className="z-10 w-full lg:w-3/4 xl:w-1/2 max-w-2xl bg-doc-bg p-6 lg:p-12 flex flex-col rounded-xl overflow-y-scroll border-white/50 border"
           initial={{ opacity: 0, y: 100 }}
           whileInView={{ opacity: 1, y: 0 }}         
           transition={{
              delay: 0.3,
              type: "spring",
              mass: 0.35,
              stiffness: 75,
            }}>
              <div className="flex justify-between">
                <button 
                tabIndex="0"
                  type="button" 
                  role="button"
                  className="apperaance-none outline-none focus:underline hidden" 
                  onClick={()=>{setShowAuthModal(false)}}                  
                >Back</button>
                <button 
                  className="appearance-none outline-none focus:underline hover:underline self-end cursor-pointer text-sm"
                  onClick={() => setShowAuthModal(false)}
                  onKeyDown={() => setShowAuthModal(false)}
                  aria-label="Hide the modal"
                  role="button"
                  type="button"
                  tabIndex="0"
                >
                  Close
                </button>           
              </div>                          

              <ModalInner slug={slug} />
           </motion.div>
         </motion.section>
        )}
      </AnimatePresence>   
    </>
  )
}

export default ProductBuilderModal